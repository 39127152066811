const getValue = <T extends string | number | boolean>(
  env: string | boolean | undefined,
  defaultValue: T,
): T => {
  try {
    if (!env) return defaultValue;

    if (Number.isInteger(Number(env))) return Number(env) as T;

    return env as T;
  } catch {
    return defaultValue;
  }
};

interface IServerConfig {
  appSignalKey: string;
  zmyleApi: string;
  prismicAccessToken: string;
  zmyleHostname: string;
  ipStackAccessToken: string;
  isProduction: boolean;
  slackWebhookUrl: string;
  slackChannel: string;
  redisHost: string;
  redisPort: number;
  redisPassword: string;
}

export class ServerConfig {
  // eslint-disable-next-line no-use-before-define
  private static instance: ServerConfig;

  private config: IServerConfig;

  private constructor() {
    this.config = {
      appSignalKey: getValue(process.env.NEXT_PUBLIC_APP_SIGNAL_KEY, ''),
      zmyleApi: getValue(process.env.WEB_API, '/ui_api/v1.1').replace(
        /\/$/,
        '',
      ),
      prismicAccessToken: getValue(process.env.PRISMIC_ACCESS_TOKEN, ''),
      zmyleHostname: getValue(process.env.ZMYLE_HOSTNAME, '').replace(
        /\/$/,
        '',
      ),
      ipStackAccessToken: getValue(process.env.IP_STACK_ACCESS_TOKEN, ''),
      isProduction: getValue(process.env.NODE_ENV === 'production', false),
      slackWebhookUrl: getValue(process.env.SLACK_WEBHOOK_URL, ''),
      slackChannel: getValue(process.env.SLACK_CHANNEL, ''),
      redisHost: getValue(process.env.REDIS_HOST, ''),
      redisPort: getValue(process.env.REDIS_PORT, 6379),
      redisPassword: getValue(process.env.REDIS_PASSWORD, ''),
    };
  }

  public static getInstance() {
    if (!ServerConfig.instance) ServerConfig.instance = new ServerConfig();

    return ServerConfig.instance;
  }

  public getConfig() {
    return this.config;
  }
}
