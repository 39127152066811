'use client';

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

interface Props extends FlexProps {
  isFullHeight?: boolean;
}

export const PageContainer = forwardRef<Props, 'div'>((props, ref) => {
  const { children, isFullHeight, ...rest } = props;

  return (
    <Flex
      ref={ref}
      w="full"
      minH={isFullHeight ? ['100vh', '100vh', 'calc(100vh - 5rem)'] : 'auto'}
      align="center"
      justify="center"
      {...rest}
    >
      {children}
    </Flex>
  );
});
