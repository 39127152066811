import { useMemo } from 'react';
import {
  AspectRatio,
  Box,
  Flex,
  HStack,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';

import { useResponsiveness } from 'hooks/useResponsiveness';
import { getCategoryIcon } from 'utils/helpers';
import { Link, Img } from 'components';
import { OutletCardSkeleton } from './OutletCardSkeleton';

const imageSizes = {
  main: {
    height: 1125,
    width: 750,
  },
  secondary: {
    height: 500,
    width: 500,
  },
} as const;

interface Props {
  outlet: AdaptedStore;
  isHorizontal?: boolean;
  isLoading?: boolean;
}

export const OutletCard = (props: Props) => {
  const { outlet, isHorizontal = false, isLoading = false } = props;

  const { isMobile } = useResponsiveness();

  const titleImageUrl = useMemo(() => {
    const url = new URL(outlet.titleImage.url);

    const height = isMobile
      ? imageSizes.main.height / 2
      : imageSizes.main.height;
    const width = isMobile ? imageSizes.main.width / 2 : imageSizes.main.width;

    url.searchParams.set('height', String(height));
    url.searchParams.set('width', String(width));
    url.searchParams.set('mode', 'fit');

    return url.href;
  }, [outlet.titleImage.url]);

  const logoImageUrl = useMemo(() => {
    if (!outlet.logoImage?.url) return '';

    const url = new URL(outlet.logoImage.url);

    const height = isMobile
      ? imageSizes.secondary.height / 2
      : imageSizes.secondary.height;
    const width = isMobile
      ? imageSizes.secondary.width / 2
      : imageSizes.secondary.width;

    url.searchParams.set('height', String(height));
    url.searchParams.set('width', String(width));
    url.searchParams.set('mode', 'fit');

    return url.href;
  }, [outlet.logoImage?.url]);

  const hasLogoImage = Boolean(logoImageUrl);

  if (isLoading) return <OutletCardSkeleton isHorizontal={isHorizontal} />;

  return (
    <Stack
      direction={isHorizontal ? 'row' : 'column'}
      spacing={isHorizontal ? '0.5rem' : 0}
      align="center"
      bg="white"
      rounded="lg"
      p={isHorizontal ? '0' : '1rem'}
      pl={isHorizontal ? '0' : '1rem'}
      shadow="lg"
      mt={isHorizontal ? '0' : '3rem'}
      maxW={isHorizontal ? 'unset' : ['full', '21.125rem']}
      w="full"
      transition="ease-in-out"
      transitionDuration="0.2s"
      as={Link}
      href={outlet.zmyleUri}
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: isHorizontal ? 1.025 : 1.05 }}
      _hover={{ color: 'unset', shadow: 'xl' }}
    >
      <Box
        pos="relative"
        w="full"
        minW={isHorizontal ? '8rem' : 'unset'}
        maxW={isHorizontal ? '12rem' : 'unset'}
        h="full"
        mt={isHorizontal ? '0' : '-3rem'}
      >
        <AspectRatio ratio={3 / 2} w="full">
          <Img
            src={titleImageUrl}
            width={imageSizes.main.width}
            height={imageSizes.main.height}
            shadow="lg"
            rounded="lg"
            alt={outlet.name}
          />
        </AspectRatio>

        {hasLogoImage && (
          <AspectRatio
            ratio={1}
            minW="3.5rem"
            w="30%"
            pos="absolute"
            right="0.25rem"
            bottom="0.25rem"
            borderWidth="0.25rem"
            borderColor="white"
            bg="white"
            rounded="md"
          >
            <Img
              src={logoImageUrl}
              width={imageSizes.secondary.width}
              height={imageSizes.secondary.height}
              shadow="lg"
              rounded="md"
              alt={`${outlet.name} logo`}
            />
          </AspectRatio>
        )}
      </Box>

      <VStack
        h="full"
        w="full"
        px="0.5rem"
        mt={isHorizontal ? '0' : '1rem'}
        align="flex-start"
        overflowX="hidden"
      >
        <Text
          fontSize="1rem"
          fontWeight={500}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace={['pre-wrap', 'pre-wrap', 'nowrap']}
          w="full"
          alignSelf="flex-start"
          title={outlet.name}
        >
          {outlet.name}
        </Text>

        <HStack justify="space-between" align="flex-end" w="full">
          <Flex direction="column" align="flex-start">
            <Text fontSize="0.875rem">{outlet.address.streetAddress}</Text>

            <Text fontSize="0.875rem">
              {outlet.address.zipcode} {outlet.address.city}
            </Text>
          </Flex>

          <Tooltip
            hasArrow
            label={outlet.category?.name}
            aria-label="outlet category"
          >
            <span>
              <Img
                src={getCategoryIcon(outlet.parentCategoryId)}
                alt="Store Category"
              />
            </span>
          </Tooltip>
        </HStack>
      </VStack>
    </Stack>
  );
};
