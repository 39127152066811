import { useMemo } from 'react';
import { AspectRatio, Box, Text } from '@chakra-ui/react';
import { Link, Img } from 'components';
import { Paths } from 'utils/paths';
import { useResponsiveness } from 'hooks/useResponsiveness';
import { NetworkCardSkeleton } from './NetworkCardSkeleton';

const imageSize = {
  height: 1125,
  width: 750,
} as const;

interface Props {
  network: AdaptedNetwork;
  isLoading?: boolean;
}

export const NetworkCard = (props: Props) => {
  const { network, isLoading = false } = props;

  const { isMobile } = useResponsiveness();

  const logoUrl = useMemo(() => {
    const url = new URL(network.giftcardFrontpage.url);

    const height = isMobile ? imageSize.height / 2 : imageSize.height;
    const width = isMobile ? imageSize.width / 2 : imageSize.width;

    url.searchParams.set('height', String(height));
    url.searchParams.set('width', String(width));
    url.searchParams.set('mode', 'fit');

    return url.href;
  }, [network.giftcardFrontpage.url]);

  if (isLoading) return <NetworkCardSkeleton />;

  return (
    <Box
      bg="white"
      rounded="md"
      p="1rem"
      pb="1.5rem"
      shadow="lg"
      mt="3rem"
      maxW={['full', '21.125rem']}
      w="full"
      border="1px solid transparent"
      as={Link}
      href={`${Paths.NETWORK_GIFTCARDS}/${network.slug}`}
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.05 }}
      _hover={{ color: 'unset' }}
    >
      <AspectRatio ratio={3 / 2} w="full" mt="-3rem">
        <Img
          src={logoUrl}
          width={imageSize.width}
          height={imageSize.height}
          shadow="lg"
          rounded="md"
          alt={network.name}
        />
      </AspectRatio>

      <Text
        fontSize="1.125rem"
        fontWeight={300}
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        w="full"
        px="0.5rem"
        mt="1rem"
        alignSelf="flex-start"
        title={network.name}
      >
        {network.name}
      </Text>
    </Box>
  );
};
