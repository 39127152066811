'use client';

import {
  forwardRef,
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
} from '@chakra-ui/react';
import { motion, MotionProps } from 'framer-motion';

export type IconButtonProps = ChakraIconButtonProps & MotionProps;

export const IconButton = forwardRef<IconButtonProps, 'button'>(
  (props, ref) => {
    const { children, isDisabled, ...rest } = props;

    return (
      <ChakraIconButton
        ref={ref}
        as={motion.button}
        whileTap={isDisabled ? {} : { scale: 0.95 }}
        whileHover={isDisabled ? {} : { scale: 1.05 }}
        isDisabled={isDisabled}
        {...rest}
      >
        {children}
      </ChakraIconButton>
    );
  },
);
