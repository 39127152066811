'use client';

import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  forwardRef,
} from '@chakra-ui/react';
import { motion, MotionProps } from 'framer-motion';

type Props = ChakraButtonProps & MotionProps;

export type ButtonProps = Props;

export const Button = forwardRef<Props, 'button'>((props, ref) => {
  const { children, isDisabled, ...rest } = props;

  return (
    <ChakraButton
      ref={ref}
      as={motion.button}
      whileHover={isDisabled ? undefined : { scale: 1.05 }}
      whileTap={isDisabled ? undefined : { scale: 0.95 }}
      isDisabled={isDisabled}
      {...rest}
    >
      {children}
    </ChakraButton>
  );
});
