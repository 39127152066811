'use client';

import { chakra, ChakraProps, useBreakpointValue } from '@chakra-ui/react';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import NextImage, {
  ImageLoader,
  ImageProps as NextImageProps,
} from 'next/image';
import { useMemo } from 'react';
import { convertToPixels } from './convertToPixels';

const ChakraNextImage = chakra(NextImage, {
  shouldForwardProp(prop) {
    return [
      'src',
      'alt',
      'width',
      'height',
      'fill',
      'loader',
      'quality',
      'priority',
      'loading',
      'placeholder',
      'blurDataURL',
      'unoptimized',
      'onLoadingComplete',
    ].includes(prop);
  },
});

type OmittedChakraProps = Omit<ChakraProps, 'height' | 'width' | 'w' | 'h'>;
type OmittedNextImageProps = Omit<
  NextImageProps,
  'fill' | 'height' | 'width' | 'color' | 'objectPosition' | 'objectFit'
>;

export type ImgSize = `${number}px` | `${number}rem` | number;

interface CommomProps extends OmittedChakraProps, OmittedNextImageProps {
  alt: string;
}

interface ExternalImportProps extends CommomProps {
  src: string;
  height: ImgSize;
  width: ImgSize;
}

interface StaticImportProps extends CommomProps {
  // https://nextjs.org/docs/app/building-your-application/optimizing/images#local-images
  src: StaticImport;
  height?: ImgSize;
  width?: ImgSize;
}

type Props = ExternalImportProps | StaticImportProps;

export const Img = (props: Props) => {
  const { height, width, ...rest } = props;

  const { heightInPx, widthInPx } = useMemo(() => {
    if (!height || !width) return {};

    return {
      heightInPx: convertToPixels(height),
      widthInPx: convertToPixels(width),
    };
  }, [height, width]);

  const imageWidth = useBreakpointValue([300, 600, 900], { ssr: true });

  const isStaticImport = typeof rest.src === 'object';

  const imageLoader: ImageLoader = ({ src }) => {
    const isStaticImage = /^\/_next\/static/.test(src);

    if (isStaticImage) return `${src}?width=${imageWidth}`;

    const imageUrl = new URL(src);

    imageUrl.searchParams.set('width', String(imageWidth));

    return imageUrl.href;
  };

  if (!imageWidth) return null;

  if (isStaticImport) {
    return <ChakraNextImage loading="lazy" loader={imageLoader} {...rest} />;
  }

  return (
    <ChakraNextImage
      loading="lazy"
      objectFit="contain"
      height={heightInPx}
      width={widthInPx}
      h="auto"
      w="full"
      maxW={width}
      sizes="100vw"
      loader={imageLoader}
      {...rest}
    />
  );
};
