import { ReactNode } from 'react';
import Link from 'next/link';
import {
  Link as ChakraLink,
  Text,
  LinkProps as ChakraLinkProps,
  StyleProps,
} from '@chakra-ui/react';
import { usePathname } from 'next/navigation';

import { doesRouteMatch } from 'utils/helpers';

interface Props extends ChakraLinkProps {
  children?: ReactNode;
  href: string;
}

export const NavLink = (props: Props) => {
  const { children, href, ...rest } = props;

  const pathname = usePathname();

  const isActive = doesRouteMatch({ signature: href, route: pathname });

  const activeStyling: StyleProps = {
    color: 'zmyle.primary',
  };

  return (
    <Link href={href} passHref>
      <ChakraLink
        as="span"
        {...rest}
        _hover={{
          color: 'zmyle.primary',
        }}
        {...(isActive ? activeStyling : {})}
      >
        <Text fontWeight="500">{children}</Text>
      </ChakraLink>
    </Link>
  );
};
