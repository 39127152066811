import { ReactNode, useRef, useState } from 'react';
import {
  FormControl,
  FormLabel,
  FormLabelProps,
  Icon,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { MdError } from 'react-icons/md';
import { FieldError } from 'react-hook-form';

import { IconButton } from 'components';

interface Props {
  error?: string | FieldError;
  hasValue?: boolean;
  label?: ReactNode;
  labelProps?: FormLabelProps;
  isRequired?: boolean;
  children?: ReactNode;
}

export type InputWrapperProps = Props;

export const InputWrapper = (props: Props) => {
  const {
    error,
    hasValue = false,
    label,
    labelProps = {},
    isRequired = false,
    children,
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const hasError = Boolean(error);
  const hasLabel = Boolean(label);

  const errorMessage = typeof error === 'string' ? error : error?.message;

  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();

  const formControlRef = useRef<HTMLDivElement>(null);

  const getLabelProps = (): FormLabelProps => {
    if (!hasLabel || (!isFocused && !hasValue)) return {};

    const formControlHeight = formControlRef.current?.offsetHeight || 0;
    const spacing = 14;

    return {
      transform: `scale(0.85) translateY(-${formControlHeight - spacing}px)`,
    };
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  return (
    <FormControl
      ref={formControlRef}
      onFocus={onFocus}
      onBlur={onBlur}
      isRequired={isRequired}
      pt="0.5rem"
    >
      {hasLabel && (
        <FormLabel
          bottom={0}
          left={0}
          position="absolute"
          backgroundColor="transparent"
          pointerEvents="none"
          px="0.25rem"
          transformOrigin="left top"
          fontWeight={300}
          requiredIndicator={
            <Text as="span" color="zmyle.primary" ml="0.25rem">
              *
            </Text>
          }
          {...labelProps}
          {...getLabelProps()}
        >
          {label}
        </FormLabel>
      )}

      <InputGroup>
        {children}

        {hasError && (
          <Tooltip
            hasArrow
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            label={errorMessage}
            bg="red.500"
            color="white"
          >
            <InputRightElement h="full">
              <IconButton
                aria-label="toggle tooltip error"
                onClick={onToggle}
                variant="ghost"
                _hover={{ bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
              >
                <Icon as={MdError} color="red.500" fontSize="1.25rem" />
              </IconButton>
            </InputRightElement>
          </Tooltip>
        )}
      </InputGroup>
    </FormControl>
  );
};
