'use client';

import { useDisclosure } from '@chakra-ui/react';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useLayout } from '../components/Layout/useLayout';

export type NavigationButton = 'solutions' | 'search' | 'login';

interface MobileNavigationContextData {
  isOpen: boolean;
  activeNav: NavigationButton | null;
  onOpenNavigation(activeNav: NavigationButton): void;
  onCloseNavigation(): void;
}

const MobileNavigationContext = createContext(
  {} as MobileNavigationContextData,
);

export const MobileNavigationProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const [activeNav, setActiveNav] = useState<NavigationButton | null>(null);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { changeIsPageScrollDisabled } = useLayout();

  useEffect(() => {
    if (isOpen) return changeIsPageScrollDisabled(true);

    changeIsPageScrollDisabled(false);
  }, [isOpen]);

  const onCloseNav = () => {
    setActiveNav(null);
    onClose();
  };

  const onOpenNavigation = (_activeNav: NavigationButton) => {
    setActiveNav(_activeNav);
    onOpen();
  };

  const value = useMemo(() => {
    return {
      isOpen,
      activeNav,
      onOpenNavigation,
      onCloseNavigation: onCloseNav,
    };
  }, [isOpen, activeNav, onOpenNavigation, onCloseNav]);

  return (
    <MobileNavigationContext.Provider value={value}>
      {children}
    </MobileNavigationContext.Provider>
  );
};

export const useMobileNavigation = () => useContext(MobileNavigationContext);
