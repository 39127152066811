import { useMemo } from 'react';
import {
  Button,
  MenuItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  ColorProps,
  Center,
  useDisclosure,
  Img,
} from '@chakra-ui/react';
import { usePathname } from 'next/navigation';

import { Paths } from 'utils/paths';
import { Link } from 'components';
import { doesRouteMatch } from 'utils/helpers';

import { useLayout } from '../../useLayout';

interface MenuItem {
  label: string;
  href: string;
  logo: string;
  color: ColorProps['color'];
}

export const SolutionsFloatingNavLink = () => {
  const { layoutContent } = useLayout();

  const pathname = usePathname();

  const { onOpen, onClose, isOpen } = useDisclosure();

  const {
    storeLogoUrl = '',
    solutionsStore = '',
    benefitsLogoUrl = '',
    solutionsBenefit = '',
    networkLogoUrl = '',
    solutionsNetwork = '',
    solutions = '',
  } = layoutContent?.topbar ?? {};

  const menu = useMemo<MenuItem[]>(() => {
    return [
      {
        label: solutionsStore,
        href: Paths.STORES,
        logo: storeLogoUrl,
        color: 'store.primary',
      },
      {
        label: solutionsBenefit,
        href: Paths.BENEFITS,
        logo: benefitsLogoUrl,
        color: 'benefit.primary',
      },
      {
        label: solutionsNetwork,
        href: Paths.NETWORKS,
        logo: networkLogoUrl,
        color: 'network.primary',
      },
    ];
  }, [layoutContent]);

  const isActive = useMemo(() => {
    const activeMenu = menu.find(menuItem =>
      doesRouteMatch({ signature: menuItem.href, route: pathname }),
    );

    return Boolean(activeMenu);
  }, [pathname]);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger="hover"
      placement="bottom"
    >
      <PopoverTrigger>
        <Button
          variant="unstyled"
          fontWeight="500"
          color={isActive ? 'zmyle.primary' : 'blue.800'}
          _hover={{
            color: 'zmyle.primary',
          }}
        >
          {solutions}
        </Button>
      </PopoverTrigger>

      <PopoverContent maxWidth="11.875rem">
        <PopoverArrow />

        <PopoverBody px="0">
          <VStack w="100%" spacing="0.5rem">
            {menu.map(menuItem => (
              <Link
                key={menuItem.href}
                href={menuItem.href}
                onClick={onClose}
                w="100%"
                py="0.5rem"
                px="1rem"
                noHoverEffect
                _hover={{
                  bg: 'gray.50',
                }}
              >
                <Img
                  src={menuItem.logo}
                  height="1.5rem"
                  alignSelf="flex-start"
                  alt="Menu Logo"
                  loading="lazy"
                />

                <Center justifyContent="flex-end" mt="0.5rem" pr="0.4rem">
                  <Text
                    maxWidth="7.5rem"
                    fontSize="0.875rem"
                    textAlign="left"
                    color={menuItem.color}
                  >
                    {menuItem.label}
                  </Text>
                </Center>
              </Link>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
