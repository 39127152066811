export enum Paths {
  HOME = '/',
  STORES = '/stores-gutscheine-fuer-lokale-unternehmen',
  BENEFITS = '/benefits-sachbezug-als-stadtgutschein-fuer-arbeitgeber',
  NETWORKS = '/networks-stadtgutschein-system-fuer-deine-smartcity',
  ZMYLE = '/zmyle-professionelle-gutscheinloesungen',
  CONTACT = '/kontakt',
  SEARCH = '/search',
  REGISTER_NETWORK = '/register-network',
  NETWORK_GIFTCARDS = '/stadtgutscheine',
  NETWORK_PRICING_CALCULATOR = '/networks-stadtgutschein-system-fuer-deine-smartcity/pricing',
  NETWORK_FEATURES = '/networks-stadtgutschein-system-fuer-deine-smartcity/funktionen',
  NETWORK_CASH_MANAGEMENT = '/networks-stadtgutschein-system-fuer-deine-smartcity/cashmanagement',
  NETWORK_LEGAL = '/networks-stadtgutschein-system-fuer-deine-smartcity/recht',
}

export enum ZmyleLinks {
  LOGIN = 'backoffice/login',
  SIGN_UP_STORE = 'backoffice/signupStore',
  SIGN_UP_COMPANY = 'backoffice/signupCompany',
  SIGN_UP = 'backoffice/signup',
  LEGAL_INFO = 'https://legal.zmyle.de/sonstiges/imprint',
  PRIVACY_POLICY = 'https://legal.zmyle.de/privacy-policy/datenschutzerklaerung',
  MANUAL = 'https://support.zmyle.de/',
  FACEBOOK = 'https://www.facebook.com/zmyle.de/',
  TWITTER = 'https://twitter.com/zmyle_de/',
  INSTAGRAM = 'https://www.instagram.com/zmyle.de/',
  MAGIC_CHECKOUT = 'https://support.zmyle.de/stores-fuer-akzeptanzstellen/verwaltung/marketing/magic-checkout',
}
