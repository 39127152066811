'use client';

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

export const pageContentDefaultProps: FlexProps = {
  w: 'full',
  h: 'full',
  px: [0, 0, '2.5rem'],
  py: '6rem',
  maxW: ['90%', '90%', '90rem'],
  flex: '1 1 auto',
} as const;

export const PageContent = forwardRef<FlexProps, 'div'>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <Flex ref={ref} {...pageContentDefaultProps} {...rest}>
      {children}
    </Flex>
  );
});
