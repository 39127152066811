import { useMemo } from 'react';
import {
  MenuItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
  IconButton,
  Icon,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';
import { Paths, ZmyleLinks } from 'utils/paths';
import { Link } from 'components';

import { useLayout } from '../../useLayout';

interface MenuItem {
  label: string;
  href: string;
}

export const AccountFloatingNavLink = () => {
  const { layoutContent } = useLayout();

  const { onOpen, onClose, isOpen } = useDisclosure();

  const {
    login = '',
    signUp = '',
    asBenefit = '',
    asNetwork = '',
    asStore = '',
  } = layoutContent?.topbar ?? {};

  const { origin } = window.location;

  const externalLinksMenu = useMemo<MenuItem[]>(() => {
    return [
      {
        label: asStore,
        href: `${origin}/${ZmyleLinks.SIGN_UP_STORE}`,
      },
      {
        label: asBenefit,
        href: `${origin}/${ZmyleLinks.SIGN_UP_COMPANY}`,
      },
    ];
  }, [layoutContent]);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      trigger="hover"
      placement="bottom"
    >
      <PopoverTrigger>
        <IconButton
          aria-label="open account menu"
          variant="ghost"
          borderRadius="50%"
          _hover={{
            bg: 'gray.50',
            color: 'zmyle.primary',
          }}
        >
          <Icon as={FaUser} fontSize="1rem" />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent maxWidth="11.875rem" mr="0.625rem">
        <PopoverArrow />

        <PopoverBody px="0">
          <VStack w="100%" spacing="0.5rem">
            <Link
              href={`${origin}/${ZmyleLinks.LOGIN}`}
              isExternal
              target="_self"
              onClick={onClose}
              w="100%"
              py="0.5rem"
              px="1rem"
              noHoverEffect
              _hover={{
                bg: 'gray.50',
                color: 'zmyle.primary',
              }}
            >
              <Text textAlign="left" w="100%">
                {login}
              </Text>
            </Link>

            <Divider />

            <Text textAlign="left" py="0.5rem" px="1rem" w="100%">
              {signUp}
            </Text>
          </VStack>

          <VStack w="100%" spacing="0">
            {externalLinksMenu.map(menuItem => (
              <Link
                key={menuItem.href}
                href={menuItem.href}
                isExternal
                target="_self"
                w="100%"
                py="0.5rem"
                px="1rem"
                noHoverEffect
                _hover={{
                  bg: 'gray.50',
                  color: 'zmyle.primary',
                }}
              >
                <Text textAlign="left" w="100%">
                  {menuItem.label}
                </Text>
              </Link>
            ))}

            <Link
              href={Paths.REGISTER_NETWORK}
              w="100%"
              py="0.5rem"
              px="1rem"
              noHoverEffect
              _hover={{
                bg: 'gray.50',
                color: 'zmyle.primary',
              }}
            >
              <VStack w="100%">
                <Text textAlign="left" w="100%">
                  {asNetwork}
                </Text>
              </VStack>
            </Link>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
