'use client';

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface UseLayoutData {
  layoutContent: LayoutContent | null;
  defaultSEO: Record<keyof SEO, string>;
  changeLayoutContent(data: LayoutContent): void;
  isPageScrollDisabled: boolean;
  changeIsPageScrollDisabled(isDisabled: boolean): void;
}

const LayoutContext = createContext({} as UseLayoutData);

interface Props {
  children: ReactNode;
  defaultSEO: Record<keyof SEO, string>;
}

export const LayoutContextProvider = (props: Props) => {
  const { children, defaultSEO } = props;

  const [content, setLayoutContent] = useState<LayoutContent | null>(null);
  const [isPageScrollDisabled, setIsPageScrollDisabled] = useState(false);

  useEffect(() => {
    if (isPageScrollDisabled) {
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'unset';
  }, [isPageScrollDisabled]);

  const changeIsPageScrollDisabled = (isDisabled: boolean) => {
    setIsPageScrollDisabled(isDisabled);
  };

  const changeLayoutContent = (data: LayoutContent) => {
    setLayoutContent(data);
  };

  const value = useMemo<UseLayoutData>(
    () => ({
      layoutContent: content,
      defaultSEO,
      changeLayoutContent,
      isPageScrollDisabled,
      changeIsPageScrollDisabled,
    }),
    [content, defaultSEO, isPageScrollDisabled],
  );

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);
