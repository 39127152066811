import { PropsWithChildren } from 'react';
import { Box, HStack } from '@chakra-ui/react';

import { Link, PageContainer, Img } from 'components';
import { Paths } from 'utils/paths';
import { useLayout } from '../../useLayout';
import { PageContent } from '../PageContent';
import { AccountFloatingNavLink } from './AccountFloatingNavLink';

import { NavLink } from './NavLink';
import { SolutionsFloatingNavLink } from './SolutionsFloatingNavLink';

export const DesktopNavigation = (props: PropsWithChildren) => {
  const { children } = props;

  const { layoutContent } = useLayout();

  const {
    zmyleLogoUrl = '',
    giftcard = '',
    zmyle = '',
    contact = '',
  } = layoutContent?.topbar || {};

  return (
    <PageContainer
      py="1.25rem"
      pos="fixed"
      bg="white"
      boxShadow="md"
      zIndex={15}
    >
      <PageContent align="center" justify="space-between" py={0}>
        <Link href={Paths.HOME} height={38} width={148}>
          <Img
            src={zmyleLogoUrl}
            alt={zmyle}
            height={10}
            width={150}
            minW={143}
          />
        </Link>

        <Box>{children}</Box>

        <HStack align="center" spacing="2rem" as="nav" fontSize="1rem">
          <NavLink href={Paths.SEARCH}>{giftcard}</NavLink>

          <SolutionsFloatingNavLink />

          <NavLink href={Paths.ZMYLE}>{zmyle}</NavLink>

          <NavLink href={Paths.CONTACT}>{contact}</NavLink>

          <AccountFloatingNavLink />
        </HStack>
      </PageContent>
    </PageContainer>
  );
};

export default DesktopNavigation;
