/* eslint-disable camelcase */
import axios from 'axios';
import { ServerConfig } from 'server/services/serverConfig';

interface IPLocation {
  latitude: string;
  longitude: string;
}

interface IpStackResponse {
  country_code: string;
  latitude: string;
  longitude: string;
}

export const getIPLocation = async (ip: string): Promise<IPLocation | null> => {
  try {
    const serverConfig = ServerConfig.getInstance().getConfig();

    const response = await axios.get<IpStackResponse>(
      `http://api.ipstack.com/${ip}`,
      {
        params: {
          access_key: serverConfig.ipStackAccessToken,
        },
      },
    );

    const { latitude, longitude, country_code = '' } = response.data;

    if (country_code.toLocaleLowerCase() !== 'de') return null;

    return { latitude, longitude };
  } catch {
    return null;
  }
};
