'use client';

import { ChangeEvent, useState } from 'react';
import {
  ChakraProps,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  forwardRef,
} from '@chakra-ui/react';

import { InputWrapper, InputWrapperProps } from './InputWrapper';

type Props = ChakraTextareaProps & InputWrapperProps;

export type TextareaProps = Props;

export const Textarea = forwardRef<Props, 'textarea'>((props, ref) => {
  const {
    error,
    label,
    labelProps,
    variant = label ? 'flushed' : undefined,
    isRequired,
    ...rest
  } = props;

  const [inputValue, setInputValue] = useState('');

  const hasError = Boolean(error);
  const hasValue = Boolean(inputValue);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);

    rest.onChange?.(e);
  };

  const getErrorStyles = (): ChakraProps => {
    if (hasError) {
      return { borderColor: 'red.500', borderWidth: '2px' };
    }

    return {};
  };

  return (
    <InputWrapper
      label={label}
      labelProps={labelProps}
      hasValue={hasValue}
      error={error}
      isRequired={isRequired}
    >
      <ChakraTextarea
        {...getErrorStyles()}
        ref={ref}
        variant={variant}
        rows={5}
        {...rest}
        onChange={onChange}
      />
    </InputWrapper>
  );
});
