export const convertToPixels = (
  value?: string | number,
): number | undefined => {
  if (!value) return undefined;

  if (typeof value === 'number') return value;

  const regex = /^(\d+\.?\d*)(px|rem)$/;
  const matches = value.match(regex);

  if (!matches) return 0;

  const number = Number(matches[1]);
  const unit = matches[2];

  if (unit === 'rem') return number * 16;

  if (unit === 'px') return number;

  return 0;
};
