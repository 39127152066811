'use client';

import { Grid, GridItem, GridItemProps, GridProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { NetworkCard } from './NetworkCard';

interface Props extends GridProps {
  networks?: AdaptedNetwork[];
  gridItemProps?: GridItemProps;
  isLoading?: boolean;
}

export const NetworksGrid = (props: Props) => {
  const { networks, gridItemProps, children, isLoading, ...rest } = props;

  return (
    <Grid
      mt="2rem"
      gridTemplateColumns={[
        'repeat(2, minmax(10rem, 1fr))',
        'repeat(2, minmax(10rem, 1fr))',
        'repeat(4, minmax(20%, 1fr))',
      ]}
      gap="1rem"
      w="full"
      alignItems="center"
      justifyItems="center"
      {...rest}
    >
      {children}

      {networks?.map(network => (
        <GridItem
          key={network.id}
          as={motion.div}
          layout
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="full"
          minW="10rem"
          maxW="25rem"
          {...gridItemProps}
        >
          <NetworkCard network={network} isLoading={isLoading} />
        </GridItem>
      ))}
    </Grid>
  );
};
