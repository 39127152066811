'use client';

import dynamic from 'next/dynamic';
import { type CurrencyInputProps as ReactCurrencyInputProps } from 'react-currency-input-field';

import { Input, InputProps } from './Input';

const ReactCurrencyInput = dynamic(() => import('react-currency-input-field'), {
  ssr: false,
});

type Props = ReactCurrencyInputProps & InputProps;

export type CurrencyInputProps = Props;

export const CurrencyInput = (props: Props) => {
  return (
    <Input
      {...props}
      as={ReactCurrencyInput}
      intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
    />
  );
};
