/* eslint-disable no-use-before-define */

import { isServer } from 'utils/helpers';

export class CookieWidget {
  private static instance: CookieWidget;

  private cookieWidget: Window['zmyleCookieWidget'];

  private constructor() {
    if (!isServer() && window.zmyleCookieWidget) {
      this.cookieWidget = window.zmyleCookieWidget;
    }
  }

  public static isAvailable(): boolean {
    return !isServer() && Boolean(window.zmyleCookieWidget);
  }

  public static getInstance(): CookieWidget {
    if (!CookieWidget.instance?.cookieWidget) {
      CookieWidget.instance = new CookieWidget();
    }

    return CookieWidget.instance;
  }

  public open(params?: OpenZmyleCookieWidget) {
    return this.cookieWidget?.open(params);
  }

  public setOnClose(cb: OpenZmyleCookieWidget['onSubmit']) {
    return this.cookieWidget?.setOnClose(cb);
  }
}
