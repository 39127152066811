import {
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import { Paths, ZmyleLinks } from 'utils/paths';
import { Button, Link, PageContainer, PageContent } from 'components';
import { CookieWidget } from 'services/cookieWidget';
import { useResponsiveness } from 'hooks/useResponsiveness';
import { useLayout } from '../useLayout';

export const Footer = () => {
  const { layoutContent } = useLayout();

  const {
    company = '',
    zmyle = '',
    giftcard = '',
    searchGiftcard = '',
    cityGiftcard = '',
    products = '',
    storeGiftcards = '',
    benefitGiftcards = '',
    networkGiftcards = '',
    support = '',
    onlineManual = '',
    contact = '',
    zmyleFullName = '',
    legalInfo = '',
    privacyPolicy = '',
    cookies = '',
  } = layoutContent?.footer || {};

  const { isMobile } = useResponsiveness();

  const year = new Date().getFullYear();

  const onCookiesClick = () => {
    const zmyleCookieWidget = CookieWidget.getInstance();

    zmyleCookieWidget.open();
  };

  return (
    <PageContainer>
      <PageContent direction="column" pt="5rem" pb={0} fontSize="0.875rem">
        <Grid
          gridTemplateColumns={['1fr', '1fr', 'repeat(4, 1fr)']}
          gap={['1.25rem', '1.25rem', '1rem']}
          mb={['1.25rem', '1.25rem', '9rem']}
        >
          <GridItem>
            <List spacing={['1.25rem', '1.25rem', '0.5rem']}>
              <ListItem display="flex">
                <Text fontWeight="500">{company}</Text>
              </ListItem>

              <ListItem display="flex">
                <Link href={Paths.ZMYLE}>{zmyle}</Link>
              </ListItem>
            </List>
          </GridItem>

          {isMobile && <Divider />}

          <GridItem>
            <List spacing={['1.25rem', '1.25rem', '0.5rem']}>
              <ListItem display="flex">
                <Text fontWeight="500">{giftcard}</Text>
              </ListItem>

              <ListItem display="flex">
                <Link href={Paths.SEARCH}>{searchGiftcard}</Link>
              </ListItem>

              <ListItem display="flex">
                <Link href={Paths.NETWORK_GIFTCARDS}>{cityGiftcard}</Link>
              </ListItem>
            </List>
          </GridItem>

          {isMobile && <Divider />}

          <GridItem>
            <List spacing={['1.25rem', '1.25rem', '0.5rem']}>
              <ListItem display="flex">
                <Text fontWeight="500">{products}</Text>
              </ListItem>

              <ListItem display="flex">
                <Link href={Paths.STORES}>{storeGiftcards}</Link>
              </ListItem>

              <ListItem display="flex">
                <Link href={Paths.BENEFITS}>{benefitGiftcards}</Link>
              </ListItem>

              <ListItem display="flex">
                <Link href={Paths.NETWORKS}>{networkGiftcards}</Link>
              </ListItem>
            </List>
          </GridItem>

          {isMobile && <Divider />}

          <GridItem>
            <List spacing={['1.25rem', '1.25rem', '0.5rem']}>
              <ListItem display="flex">
                <Text fontWeight="500">{support}</Text>
              </ListItem>

              <ListItem display="flex">
                <Link href={ZmyleLinks.MANUAL} target="_blank" isExternal>
                  {onlineManual}
                </Link>
              </ListItem>

              <ListItem display="flex">
                <Link href={Paths.CONTACT}>{contact}</Link>
              </ListItem>
            </List>
          </GridItem>
        </Grid>

        <Divider />

        <Flex
          direction={['column-reverse', 'column-reverse', 'row']}
          pt="1.25rem"
          justify="space-between"
          pb={['6rem', '6rem', '1.25rem']}
        >
          <HStack
            align="center"
            spacing="0.25rem"
            whiteSpace="nowrap"
            flexWrap="wrap"
          >
            <Text>© {year}</Text>

            <Text>.</Text>

            <Text>{zmyleFullName}</Text>

            <Text>.</Text>

            <Link
              href={ZmyleLinks.LEGAL_INFO}
              isExternal
              target="_blank"
              _hover={{ color: 'zmyle.primary' }}
            >
              {legalInfo}
            </Link>

            <Text>.</Text>

            <Link
              href={ZmyleLinks.PRIVACY_POLICY}
              isExternal
              target="_blank"
              _hover={{ color: 'zmyle.primary' }}
            >
              {privacyPolicy}
            </Link>

            <Text>.</Text>

            <Button
              variant="link"
              _hover={{ color: 'zmyle.primary' }}
              onClick={onCookiesClick}
            >
              {cookies}
            </Button>
          </HStack>

          <HStack
            color="white"
            fontSize="1rem"
            spacing="1rem"
            mb={['1rem', '1rem', 0]}
          >
            <Link
              href={ZmyleLinks.INSTAGRAM}
              isExternal
              target="_blank"
              _hover={{ color: 'white' }}
              bg="zmyle.primary"
              borderRadius="0.25rem"
              p="0.25rem"
              display="flex"
              aria-label="Open Instagram profile"
            >
              <Icon as={FaInstagram} />
            </Link>

            <Link
              href={ZmyleLinks.FACEBOOK}
              isExternal
              target="_blank"
              _hover={{ color: 'white' }}
              bg="zmyle.primary"
              borderRadius="0.25rem"
              p="0.25rem"
              display="flex"
              aria-label="Open Facebook page"
            >
              <Icon as={FaFacebookF} />
            </Link>

            <Link
              href={ZmyleLinks.TWITTER}
              isExternal
              target="_blank"
              _hover={{ color: 'white' }}
              bg="zmyle.primary"
              borderRadius="0.25rem"
              p="0.25rem"
              display="flex"
              aria-label="Open Twitter profile"
            >
              <Icon as={FaTwitter} />
            </Link>
          </HStack>
        </Flex>
      </PageContent>
    </PageContainer>
  );
};
