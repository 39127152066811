import { HStack, Icon, Text, IconButton } from '@chakra-ui/react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Link } from 'components/Link/Link';

interface Props {
  pagination: Paginated<unknown>;
  onPageChange?(page: number): void;
  getPageHref?(page: number): string;
}

export const Pagination = (props: Props) => {
  const { pagination, onPageChange, getPageHref } = props;

  const { nextPage, prvPage } = pagination;

  const currentPage = pagination.page + 1;

  const isPreviousPageDisabled = typeof prvPage !== 'number' || prvPage < 0;
  const isNextPageDisabled = !nextPage;

  const nextPageHref =
    getPageHref?.(currentPage + 1) || `?page=${currentPage + 1}`;
  const previousPageHref =
    getPageHref?.(currentPage - 1) || `?page=${currentPage - 1}`;

  const onPreviousPageClick = () => {
    onPageChange?.(currentPage - 1);
  };

  const onNextPageClick = () => {
    onPageChange?.(currentPage + 1);
  };

  return (
    <HStack spacing="1rem">
      <IconButton
        as={isPreviousPageDisabled ? undefined : Link}
        href={previousPageHref}
        aria-label="Previous Page"
        onClick={onPreviousPageClick}
        size="sm"
        rounded="full"
        fontSize="0.875rem"
        bg="white"
        color="blue.800"
        _hover={{ bg: 'zmyle.secondary', color: 'white' }}
        isDisabled={isPreviousPageDisabled}
        _disabled={{
          cursor: 'not-allowed',
          opacity: 0.6,
          _hover: { bg: 'white', color: 'blue.800' },
        }}
      >
        <Icon as={FiChevronLeft} fontSize="1.5rem" />
      </IconButton>

      <Text>{currentPage}</Text>

      <IconButton
        as={isNextPageDisabled ? undefined : Link}
        href={nextPageHref}
        aria-label="Next Page"
        onClick={onNextPageClick}
        size="sm"
        rounded="full"
        fontSize="0.875rem"
        bg="white"
        color="blue.800"
        _hover={{ bg: 'zmyle.secondary', color: 'white' }}
        isDisabled={isNextPageDisabled}
        _disabled={{
          cursor: 'not-allowed',
          opacity: 0.6,
          _hover: { bg: 'white', color: 'blue.800' },
        }}
      >
        <Icon as={FiChevronRight} fontSize="1.5rem" />
      </IconButton>
    </HStack>
  );
};
