'use client';

import { useMemo } from 'react';
import {
  Link as ChakraNextLink,
  LinkProps as ChakraNextLinkProps,
} from '@chakra-ui/next-js';
import { motion, MotionProps } from 'framer-motion';
import { Link as ChakraLink, forwardRef } from '@chakra-ui/react';

const MotionChakraNextLink = motion(ChakraNextLink);
const MotionChakraLink = motion(ChakraLink);

type Props = ChakraNextLinkProps &
  MotionProps & {
    noHoverEffect?: boolean;
    isExternal?: boolean;
  };

export const Link = forwardRef<Props, 'a'>((props, ref) => {
  const {
    href = '',
    noHoverEffect = false,
    isExternal = false,
    ...rest
  } = props;

  const LinkComponent = isExternal ? MotionChakraLink : MotionChakraNextLink;

  const hoverStyles = useMemo<ChakraNextLinkProps['_hover']>(() => {
    if (noHoverEffect) return {};

    return { color: 'zmyle.primary' };
  }, [noHoverEffect]);

  const animation = useMemo(() => {
    if (noHoverEffect) return {};

    return {
      whileHover: { scale: 1.05 },
      whileTap: { scale: 0.95 },
    };
  }, [noHoverEffect]);

  return (
    <LinkComponent
      ref={ref}
      href={href}
      _hover={hoverStyles}
      {...animation}
      {...rest}
    />
  );
});
