import { AspectRatio, Skeleton, SkeletonText, Stack } from '@chakra-ui/react';

interface Props {
  isHorizontal?: boolean;
}

export const OutletCardSkeleton = (props: Props) => {
  const { isHorizontal = false } = props;

  return (
    <Stack
      direction={isHorizontal ? 'row' : 'column'}
      spacing={isHorizontal ? '0.5rem' : 0}
      align="center"
      bg="white"
      rounded="md"
      p={isHorizontal ? '1.25rem' : '1rem'}
      shadow="lg"
      mt={isHorizontal ? '0' : '3rem'}
      maxW={isHorizontal ? 'unset' : '25rem'}
      w="full"
      minW="13rem"
      maxH={isHorizontal ? '11rem' : 'unset'}
    >
      <Skeleton
        rounded="md"
        w="full"
        minW={isHorizontal ? '17.5rem' : undefined}
        maxW={isHorizontal ? '50%' : undefined}
        h="full"
        mt={isHorizontal ? '0' : '-3rem'}
        ml={isHorizontal ? '-1.5rem' : '0'}
      >
        <AspectRatio ratio={3 / 2} w="full">
          <Skeleton shadow="lg" />
        </AspectRatio>
      </Skeleton>

      <SkeletonText
        w="full"
        h="full"
        noOfLines={4}
        mt="1.75rem"
        spacing="1.25rem"
        pb="0.75rem"
      />
    </Stack>
  );
};
