import { AspectRatio, Box, Skeleton, SkeletonText } from '@chakra-ui/react';

export const NetworkCardSkeleton = () => {
  return (
    <Box
      bg="white"
      rounded="md"
      p="1rem"
      shadow="lg"
      mt="3rem"
      w="full"
      maxW="25rem"
    >
      <Skeleton pos="relative" rounded="md">
        <AspectRatio ratio={3 / 2} w="full" mt="-3rem">
          <Skeleton shadow="lg" />
        </AspectRatio>
      </Skeleton>

      <SkeletonText noOfLines={1} mt="1.75rem" spacing="1.25rem" pb="0.75rem" />
    </Box>
  );
};
