'use client';

import { forwardRef, ForwardRefRenderFunction } from 'react';
import dynamic from 'next/dynamic';

import { type CurrencyInputProps } from 'react-currency-input-field';

import { Input, InputProps } from './Input';

const ReactCurrencyInput = dynamic(() => import('react-currency-input-field'), {
  ssr: false,
});

type Props = CurrencyInputProps & InputProps;

export type PercentageInputProps = Props;

const PercentageInputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  props,
  ref,
) => {
  return (
    <Input
      {...props}
      as={ReactCurrencyInput}
      suffix="%"
      groupSeparator="."
      decimalSeparator=","
      ref={ref}
    />
  );
};

export const PercentageInput = forwardRef(PercentageInputBase);
