import { useBreakpointValue } from '@chakra-ui/react';

export const useResponsiveness = () => {
  const isMobile =
    useBreakpointValue(
      { base: true, md: false },
      {
        ssr: true,
      },
    ) ?? false;

  return { isMobile };
};
