'use client';

import { ChangeEvent, useState } from 'react';
import {
  ChakraProps,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  forwardRef,
} from '@chakra-ui/react';

import { InputWrapper, InputWrapperProps } from './InputWrapper';

type Props = ChakraInputProps & InputWrapperProps;

export type InputProps = Props;

export const Input = forwardRef<Props, 'input'>((props, ref) => {
  const {
    error,
    label,
    labelProps,
    variant = label ? 'flushed' : undefined,
    isRequired,
    ...rest
  } = props;

  const [inputValue, setInputValue] = useState('');

  const hasError = Boolean(error);
  const hasValue = Boolean(inputValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);

    rest.onChange?.(e);
  };

  const getErrorStyles = (): ChakraProps => {
    if (!hasError) return {};

    if (variant !== 'flushed') {
      return { borderColor: 'red.500', borderWidth: '2px' };
    }

    return {
      borderColor: 'red.500',
      borderBottomWidth: '2px',
      _focus: { borderBottomWidth: '1px' },
    };
  };

  return (
    <InputWrapper
      label={label}
      labelProps={labelProps}
      hasValue={hasValue}
      error={error}
      isRequired={isRequired}
    >
      <ChakraInput
        {...getErrorStyles()}
        ref={ref}
        variant={variant}
        {...rest}
        onChange={onChange}
      />
    </InputWrapper>
  );
});
