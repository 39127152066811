'use client';

import { Grid, GridItem, GridItemProps, GridProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { OutletCard } from './OutletCard';

interface Props extends GridProps {
  outlets?: AdaptedStore[];
  gridItemProps?: GridItemProps;
  isCardHorizontal?: boolean;
  isLoading?: boolean;
}

export const OutletsGrid = (props: Props) => {
  const {
    outlets,
    gridItemProps,
    children,
    isCardHorizontal = false,
    isLoading,
    ...rest
  } = props;

  return (
    <Grid
      mt="2rem"
      gridTemplateColumns={[
        `repeat(${isCardHorizontal ? 1 : 2}, minmax(10rem, 1fr))`,
        'repeat(2, minmax(10rem, 1fr))',
        `repeat(${isCardHorizontal ? 1 : 4}, minmax(20%, 1fr))`,
      ]}
      gap="1rem"
      w="full"
      alignItems="center"
      justifyItems="center"
      {...rest}
    >
      {children}

      {outlets?.map(outlet => (
        <GridItem
          key={outlet.id}
          as={motion.div}
          layout
          display="flex"
          alignItems="center"
          justifyContent="center"
          w="full"
          minW="10rem"
          maxW={isCardHorizontal ? undefined : '25rem'}
          {...gridItemProps}
        >
          <OutletCard
            outlet={outlet}
            isHorizontal={isCardHorizontal}
            isLoading={isLoading}
          />
        </GridItem>
      ))}
    </Grid>
  );
};
